import * as React from "react";
import styled from "styled-components";
import Form from "../components/form";
import Gallery from "../components/gallery";
import Map from "../components/map";
import Services from "../components/services";
import Staff from "../components/staff";
import Header from "../components/header";
import Helmet from "react-helmet";
import "@fontsource/lobster";

import "../styles/styles.css";

const SiteContainer = styled.div`
  display: flex;
  .mainSection {
  }

  h2 {
    font-size: 30px;
    margin: 0px 0px 10px 0px;
    text-align: center;
    padding: 6px 0px;
    background-color: #222222;
    color: white;
  }
`;

const LeftContainer = styled.div`
  display: none;

  

  @media screen and (min-width: 600px) {
    display: block;
    width: 10%;
    background: #444444;
  }
`;

const RightContainer = styled.div`
  display: none;

  @media screen and (min-width: 600px) {
    display: block;
    width: 10%;
    background: #444444;
  }
`;

const MainContainer = styled.main`
  font-family: "Lobster";

  .footerSection {
    margin-top: 30px;
    background-color: black;
    height: 100px;
  }

  .formSection {
    text-align: center;

    form {
      font-family: Arial, Helvetica, sans-serif;
      margin: 60px 0px;
      font-size: 30px;

      label {
        margin-right: 10px;
      }
      input {
        font-size: 30px;
        border: 1px solid black;
        margin-bottom: 20px;
        padding: 0px 10px;
      }

      
    }

  }

.ourLocationSection {
text-align: center;
font-size: 40px;

padding: 20px 0px;
}

  .reviewsSection {
    .reviewContainer {
      background-color: gold;
      margin: 14px 10px;
      padding: 10px;
      border-radius: 10px;

      .reviewText {
        font-style: italic;
      }
      .reviewName {
        margin-top: 7px;
        text-align: right;
      }
      }
    }
  }
`;

// markup
const IndexPage = () => {
  return (
    <>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <title>Legendz Classic Barbershop</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </Helmet>
      <SiteContainer>
        <LeftContainer></LeftContainer>
        <MainContainer>
          <section className="mainSection">
            <Header />
          </section>

          <section className="servicesSection">
            <h2>Our Services</h2>

            <Services />
          </section>

          <section className="teamSection">
            <h2>Our Team</h2>

            <Staff />
          </section>

          <section className="ourLocationSection">
            <a name="map"></a>
            <h2>Our Location</h2>
            <br />
            <div>207 N Center St</div>
            <div>Arlington, TX 76011</div>
            <div>817-985-4247</div>
            <br />

            <Map></Map>
          </section>

          <section className="reviewsSection">
            <h2>Testimonials</h2>
            <div className="reviewContainer">
              <div className="reviewText">
                "Just got my hair and beard shaped up by David. He did an
                excellent job."
              </div>
              <div className="reviewName">B.A. Autery</div>
            </div>

            <div className="reviewContainer">
              <div className="reviewText">
                "Anytime I'm in Arlington I have to schedule a stop at Legendz.
                Awesome service from Nacho.""
              </div>
              <div className="reviewName">Mike Ricker</div>
            </div>

            <div className="reviewContainer">
              <div className="reviewText">
                "Old school barbershop. Great service. Very nice and classy
                place.""
              </div>
              <div className="reviewName">Jr Barraza</div>
            </div>
          </section>

          <section className="formSection">
            <h2>Join our Newsletter</h2>
            <Form />
          </section>

          <section className="photosSection">
            <h2>Photo Gallery</h2>
            <Gallery />
          </section>
          <div className="footerSection">.</div>
        </MainContainer>
        <RightContainer></RightContainer>
      </SiteContainer>
    </>
  );
};

export default IndexPage;
