import React from "react";
import { useStaticQuery, graphql } from "gatsby"; // to query for image data
import Img from "gatsby-image"; //
import styled from "styled-components";

const StaffContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  grid-gap: 10px;

  .staffItem {
    flex-basis: 95%;
    justify-content: space-evenly;
    position: relative;

    h5 {
      font-family: "Arial";
      font-weight: 900;
      position: absolute;
      z-index: 1;
      width: 100%;
      text-align: center;
      color: white;
      margin: 0;
      font-size: 28px;
      background-color: rgba(0, 0, 0, 0.4);
      display: block;
      top: 0px;
    }

    a {
      position: absolute;
      z-index: 1;
      width: 100%;
      text-align: center;
      color: white;
      margin: 0;
      font-size: 35px;
      background-color: rgba(0, 0, 0, 0.4);
      display: block;
      text-decoration: none;
      bottom: 0px;
    }
    .staffImage {
      height: 270px;
      object-fit: cover;
      filter: grayscale(1);
    }
  }
  @media screen and (min-width: 600px) {
    .staffItem {
      flex-basis: 45%;
    }
  }
`;

export default function Staff() {
  const data = useStaticQuery(graphql`
    query {
      staffNacho: file(relativePath: { eq: "staffNacho.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      staffJosh: file(relativePath: { eq: "staffJosh.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      staffJuanDelSol: file(relativePath: { eq: "staffJuanDelSol.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      staffAngel: file(relativePath: { eq: "staffAngel.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      staffNahumDelarosa: file(relativePath: { eq: "staffNahumDelarosa.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      staffIsa: file(relativePath: { eq: "staffIsa.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      staffJuanHernandez: file(relativePath: { eq: "staffJuanHernandez.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      staffAaronGarcia: file(relativePath: { eq: "staffAaronGarcia.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      staffMary: file(relativePath: { eq: "staffMary.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      staffNatisha: file(relativePath: { eq: "staffNatisha.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      staffIsabella: file(relativePath: { eq: "staffIsabella.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      staffZaqQuintana: file(relativePath: { eq: "staffZaqQuintana.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      staffJulianEspinsoza: file(
        relativePath: { eq: "staffJulianEspinsoza.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <StaffContainer>
      <div className="staffItem">
        <Img
          className="staffImage"
          fluid={data.staffNacho.childImageSharp.fluid}
          alt="Nacho Armijo"
        />
        <h5>Nacho Armijo - Owner/Barber</h5>
        <a href="//styleseat.com/nacho">Book Appointment</a>
      </div>

      <div className="staffItem">
        <Img
          className="staffImage"
          fluid={data.staffJosh.childImageSharp.fluid}
          alt=""
        />
        <h5>Josh Ibanez</h5>
        <a href="//styleseat.com/Joshibanez">Book Appointment</a>
      </div>

      <div className="staffItem">
        <Img
          className="staffImage"
          fluid={data.staffNahumDelarosa.childImageSharp.fluid}
          alt=""
        />
        <h5>Nahum Delarosa</h5>
        <a href="//www.styleseat.com/m/v/nahumdelarosa">Book Appointment</a>
      </div>

      <div className="staffItem">
        <Img
          className="staffImage"
          fluid={data.staffJuanHernandez.childImageSharp.fluid}
          alt=""
        />
        <h5>Juan Hernandez</h5>
        <a href="//styleseat.com/m/v/juanhdz">Book Appointment</a>
      </div>

      <div className="staffItem">
        <Img
          className="staffImage"
          fluid={data.staffJulianEspinsoza.childImageSharp.fluid}
          alt=""
        />
        <h5>Julian Espinoza</h5>
        <a href="//booksy.info/WestThaBarber">Book Appointment</a>
      </div>

      <div className="staffItem">
        <Img
          className="staffImage"
          fluid={data.staffAaronGarcia.childImageSharp.fluid}
          alt=""
        />
        <h5>Aaron Garcia</h5>
        <a href="//styleseat.com/AaronGarcia">Book Appointment</a>
      </div>

      <div className="staffItem">
        <Img
          className="staffImage"
          fluid={data.staffMary.childImageSharp.fluid}
          alt=""
        />
        <h5>Marymar Magana</h5>
        <a href="//styleseat.com/marymarmagana">Book Appointment</a>
      </div>

      <div className="staffItem">
        <Img
          className="staffImage"
          fluid={data.staffNatisha.childImageSharp.fluid}
          alt=""
        />
        <h5>Natisha Ibanez</h5>
        <a href="//styleseat.com/natishaibanez">Book Appointment</a>
      </div>

      <div className="staffItem">
        <Img
          className="staffImage"
          fluid={data.staffZaqQuintana.childImageSharp.fluid}
          alt=""
        />
        <h5>Zaq Quintana</h5>
        <a href="//styleseat.com/zacharyquintana">Book Appointment</a>
      </div>

      <div className="staffItem">
        <Img
          className="staffImage"
          fluid={data.staffAngel.childImageSharp.fluid}
          alt=""
        />
        <h5>Angel Da Barber</h5>
        <a href="//www.styleseat.com/m/v/angelmota">Book Appointment</a>
      </div>
    </StaffContainer>
  );
}
