import React from "react";
import { useStaticQuery, graphql } from "gatsby"; // to query for image data
import Img from "gatsby-image"; //
import styled from "styled-components";

const ServicesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  grid-gap: 30px;
  margin-bottom: 60px;

  > div {
    width: 45%;
    background: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .serviceIcon {
  }

  h5 {
    font-size: 22px;
  }
  p {
    font-size: 20px;
  }
`;

export default function Services() {
  const data = useStaticQuery(graphql`
    query {
      barberIcon: file(relativePath: { eq: "barberIcon.png" }) {
        childImageSharp {
          fixed(width: 70) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <ServicesContainer>
      <div>
        <Img
          fixed={data.barberIcon.childImageSharp.fixed}
          className="serviceIcon"
          alt="Scissors"
        />
        <h5>Beard Trims</h5>
        <p>$20-30</p>
      </div>

      <div>
        <Img
          fixed={data.barberIcon.childImageSharp.fixed}
          className="serviceIcon"
          alt="Scissors"
        />
        <h5>Edge Up</h5>
        <p>$10-20</p>
      </div>

      <div>
        <Img
          fixed={data.barberIcon.childImageSharp.fixed}
          className="serviceIcon"
          alt="Scissors"
        />
        <h5>Head Shaves</h5>
        <p>$35</p>
      </div>

      <div>
        <Img
          fixed={data.barberIcon.childImageSharp.fixed}
          className="serviceIcon"
          alt="Scissors"
        />
        <h5>Lil Gentlemen's Haircut</h5>
        <p>$25-30</p>
      </div>

      <div>
        <Img
          fixed={data.barberIcon.childImageSharp.fixed}
          className="serviceIcon"
          alt="Scissors"
        />
        <h5>Straight Razor Shaves</h5>
        <p>$30</p>
      </div>

      <div>
        <Img
          fixed={data.barberIcon.childImageSharp.fixed}
          className="serviceIcon"
          alt="Scissors"
        />
        <h5>Gentlemen's Haircut</h5>
        <p>$25-35</p>
      </div>
    </ServicesContainer>
  );
}
