import React from "react";
import { useStaticQuery, graphql } from "gatsby"; // to query for image data
import Img from "gatsby-image"; //
import styled from "styled-components";

const GalleryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  grid-gap: 10px;

  > div {
    border-radius: 5px;
    height: 250px;
    flex-basis: 95%;

    @media screen and (min-width: 600px) {
      flex-basis: 45%;
    }
  }
`;

export default function Gallery() {
  const data = useStaticQuery(graphql`
    query {
      gallery1: file(relativePath: { eq: "gallery1.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gallery2: file(relativePath: { eq: "gallery2.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gallery3: file(relativePath: { eq: "gallery3.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gallery4: file(relativePath: { eq: "gallery4.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gallery5: file(relativePath: { eq: "gallery5.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gallery6: file(relativePath: { eq: "gallery6.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gallery7: file(relativePath: { eq: "gallery7.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gallery8: file(relativePath: { eq: "gallery8.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gallery9: file(relativePath: { eq: "gallery9.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gallery10: file(relativePath: { eq: "gallery10.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gallery11: file(relativePath: { eq: "gallery11.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gallery12: file(relativePath: { eq: "gallery12.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gallery13: file(relativePath: { eq: "gallery13.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gallery14: file(relativePath: { eq: "gallery14.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gallery15: file(relativePath: { eq: "gallery15.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gallery16: file(relativePath: { eq: "gallery16.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <GalleryContainer>
      <Img fluid={data.gallery1.childImageSharp.fluid} alt="gallery image" />
      <Img fluid={data.gallery2.childImageSharp.fluid} alt="gallery image" />
      <Img fluid={data.gallery3.childImageSharp.fluid} alt="gallery image" />
      <Img fluid={data.gallery4.childImageSharp.fluid} alt="gallery image" />
      <Img fluid={data.gallery5.childImageSharp.fluid} alt="gallery image" />
      <Img fluid={data.gallery6.childImageSharp.fluid} alt="gallery image" />
      <Img fluid={data.gallery7.childImageSharp.fluid} alt="gallery image" />
      <Img fluid={data.gallery8.childImageSharp.fluid} alt="gallery image" />
      <Img fluid={data.gallery9.childImageSharp.fluid} alt="gallery image" />
      <Img fluid={data.gallery10.childImageSharp.fluid} alt="gallery image" />
      <Img fluid={data.gallery11.childImageSharp.fluid} alt="gallery image" />
      <Img fluid={data.gallery12.childImageSharp.fluid} alt="gallery image" />
      <Img fluid={data.gallery13.childImageSharp.fluid} alt="gallery image" />
      <Img fluid={data.gallery14.childImageSharp.fluid} alt="gallery image" />
      <Img fluid={data.gallery15.childImageSharp.fluid} alt="gallery image" />
      <Img fluid={data.gallery16.childImageSharp.fluid} alt="gallery image" />
    </GalleryContainer>
  );
}
