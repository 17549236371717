import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneSquare,
  faMapMarkedAlt,
} from "@fortawesome/free-solid-svg-icons";
import { StaticImage } from "gatsby-plugin-image";

const NavContainer = styled.nav`
  background-color: black;
  display: flex;
  align-items: center;

  color: white;

  a {
    color: white;
    text-align: center;
  }

  > * {
    flex: 1;
  }

  .logo {
    width: 218px;
    height: 125px;
    flex: 3;

    img {
      object-fit: contain !important;
    }
  }
`;

export default function Header() {
  return (
    <NavContainer>
      <a href="#map">
        <FontAwesomeIcon size="3x" icon={faMapMarkedAlt} />
      </a>
      <StaticImage
        className="logo"
        src="../images/LogowGlow.png"
        alt="Legendz Classic Logo"
        layout="constrained"
        height={125}
        width={218}
      />
      <a href="tel:181754247">
        <FontAwesomeIcon size="3x" icon={faPhoneSquare} />
      </a>
    </NavContainer>
  );
}
